angular.module('sq.common.errors.service', []).factory('errorFactory', errors);

function errors() {
  var e = {};

  function UnauthorizeError(message, status) {
    this.name = 'UnauthorizeError';
    this.message = message || 'Default Message';
    this.stack = (new Error()).stack;
    this.status = status || 401;
  }

  UnauthorizeError.prototype = Object.create(Error.prototype);
  UnauthorizeError.prototype.constructor = UnauthorizeError;

  e.unauthorized = UnauthorizeError;
  
  return e;
}
